
    import { defineComponent, onMounted, ref } from 'vue';
    import {ElMessage, ElMessageBox} from "element-plus/es";
    import {useRoute, useRouter} from "vue-router";
    import "vditor/dist/index.css"
    import EditTitleLine from "@/components/vditor-notes/EditTitleLine.vue";
    import {toRefs} from "@vue/reactivity";
    import useNotes from '@/composables/useNotes';
    import uesVditor from "@/composables/vditor";
    import Vditor from "vditor";

    export default defineComponent({
        name: "Edit",
        components: {
            EditTitleLine,
        },
        setup() {
          const hideEditingTitle = localStorage.getItem('folderModeEditingTitleShow') === 'no';
            interface State {
                id: number,
                isSaved: boolean
            }
            const state: State = ({
                id: -1,
                isSaved: true
            })

            const name = ref('')
            const vditor = ref<Vditor>()

            const router = useRouter();
            const route = useRoute();
            const {getNote, updateNote} = useNotes();

            state.id = Number(Array.isArray(route.params.noteId) ? route.params.noteId[0] : route.params.noteId)

            onMounted(() => {
                vditor.value = uesVditor(state.id, onContentChange);
                setTimeout(() => {
                    state.id && get(state.id);
                }, 500)
            })

            const goBack = () => {
                router.back();
            }

            const onTitleChange = (value: string) => {
                name.value = value
                state.isSaved = false
            }

            const onContentChange = (value: string) => {
                state.isSaved = false
            }

            const onSubmitForm = () => {
                if (vditor.value == undefined) {
                  ElMessage({
                    showClose: true,
                    message: '保存失败，vditor不存在！',
                    type: 'error',
                  });
                  return
                }
                updateNote(state.id, name.value, vditor.value.getValue()).then(function (response) {
                    if (!response) {
                        return
                    }
                    ElMessage({
                        showClose: true,
                        message: '保存成功',
                        type: 'success',
                    });
                    state.isSaved = true
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '保存失败',
                            type: 'error',
                        });
                    }
                });
            }

            const get = (noteId: number) => {
                getNote(noteId).then(function(response) {
                    name.value = response.data.note.name;
                    if (response.data.note.defaultRendering == 2) {
                        vditor.value?.setPreviewMode('editor')

                        const svButton = document.querySelectorAll("[data-mode='sv']");
                        const clickEvent2 = new Event("click")
                        svButton.length > 0 && svButton[0].dispatchEvent(clickEvent2)
                    }
                    vditor.value?.setValue(response.data.note.content);
                }).catch(function (error) {
                    // console.log(error);
                    const data = !error.response ? null : error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '获取数据失败',
                            type: 'error',
                        });
                    }
                })
            }

            return {
                ...toRefs(state),
                goBack,
                onTitleChange,
                onSubmitForm,
                name,
                hideEditingTitle
            }
        },

        async beforeRouteLeave(to, from) {
            if (!this.isSaved) {
                const answer = await ElMessageBox.confirm(
                    '内容还没保存！确定离开吗？',
                    '内容还没保存！',
                    {
                        confirmButtonText: '确定离开',
                        cancelButtonText: '暂不离开',
                        type: 'warning',
                    }
                );
                if (!answer) return false
            }
        }
    })
